import React from 'react';
import { renderToString } from 'react-dom/server';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { LicenseInfo } from '@mui/x-license-pro';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import _ from 'lodash';
import moment from 'moment';

import initFontAwesome from './util/fontAwesome';
import { initTranslation } from './util/t';
import getStore from './modules/store';
import './issue-tracking';
import { ClientWrapperComponent, SSRWrapperComponent } from './components/RootWrapperComponent';

LicenseInfo.setLicenseKey('7435ead768f645cdd1cfb0b2d30f9797Tz04MTYzMSxFPTE3MzYwODY4NDQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

// Listing of React Containers <=> DOM Element associations
const COMPONENTS_TO_DOM = {
  listing: 'listing-container', // /listing
  search: 'search-container', // /search
  subscriptions: 'subscriptions-container', // /subscriptions
};

// Server-side rendering
export const getSSRContainer = async (container, window = {}, req = {}) => {
  const { RootComponent, SCOPED_REDUCERS, router, prepare } = await import(`./containers/${container}/index`);

  moment.locale(req.userLanguage);

  // Set default state
  window.defaultState = {
    ...(window.defaultState || {}),
    user: req.userSession ? _.get(req.userSession.USER, 'data[0]', { id: null }) : { id: null },
  };

  global.window = window;

  const store = getStore(SCOPED_REDUCERS);
  let extras = {};

  if (prepare) {
    extras = await prepare({ store, window }) || {};
  }

  initFontAwesome();
  initTranslation(req.t, 'angular.');

  const WrappedRootComponent = (
    <Provider store={store}>
      <SSRWrapperComponent router={router}>
        <RootComponent />
      </SSRWrapperComponent>
    </Provider>
  );

  return {
    isSSR: true,
    ssrHTML: renderToString(WrappedRootComponent),
    ssrStoreState: store.getState(),
    ...extras,
  }
};

async function loadComponents() {
  let components = 0;

  for (let path in COMPONENTS_TO_DOM) {
    const domContainer = document.getElementById(COMPONENTS_TO_DOM[path]);

    if (domContainer && ++components) {
      // Use dynamic imports to lazy load components
      const { RootComponent, SCOPED_REDUCERS, router } = await import(`./containers/${path}/index.jsx`);

      const WrappedRootComponent = ({ store }) => (
        <Provider store={store}>
          <ClientWrapperComponent router={router}>
            <RootComponent />
          </ClientWrapperComponent>
        </Provider>
      );

      if (window.__HYDRATED__[path] || domContainer.childNodes.length === 0) {
        window.__HYDRATED__[path] = window.__HYDRATED__[path] || {
          store: getStore(SCOPED_REDUCERS),
          root: createRoot(domContainer),
        };

        window.__HYDRATED__[path].root.render(<WrappedRootComponent store={window.__HYDRATED__[path].store} />);
      } else {
        const store = getStore(SCOPED_REDUCERS);
        window.__HYDRATED__[path] = {
          store,
          root: hydrateRoot(domContainer, <WrappedRootComponent store={store} />)
        }
      }
    }
  }

  if (!components && !window.ngAppInit) {
    window.ngAppInit = true;
    const html = document.documentElement;
    angular.element(document).ready(function(){
      angular.bootstrap(html, [html.dataset.ngAppName]);

      const event = new Event('LayoutHydrated');
      document.dispatchEvent(event);
    });
  }
}

// Init translations
const i18nReady = setInterval(async () => {
  if (typeof window !== 'undefined') {
    moment.locale(env.user_language);
    initTranslation();
    initFontAwesome();

    if (window.__HYDRATED__) {
      clearInterval(i18nReady);

      await loadComponents();
    } else if (window.LOCALE) {
      window.__HYDRATED__ = {};
      clearInterval(i18nReady);

      await i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
          resources: { [window.LOCALE.locale]: { translation: window.LOCALE } },
          lng: window.LOCALE.locale,
          fallbackLng: window.LOCALE.locale,
          interpolation: { escapeValue: false },
        });

      await loadComponents();
    }
  } else {
    clearInterval(i18nReady);
  }
}, 200);

if (process.env.NODE_ENV !== 'production') {
  // This is used by webpack-dev-server to enable hot reloading
  if (module.hot) {
    module.hot.accept();
  }
}
