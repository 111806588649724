import React, { useEffect } from 'react';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

export function SSRWrapperComponent({ router, children }) {
  let RouterWrapper = ({ children }) => (<>{children}</>);

  if (router) {
    switch (router.type) {
      case 'BrowserRouter':
        RouterWrapper = ({ children }) => (<StaticRouter>{children}</StaticRouter>)
        break;
    }
  }

  return (
    <RouterWrapper>
      {children}
    </RouterWrapper>
  );
}

function HandlerComponents() {
  const { openedModals } = useSelector((state) => state.layout, shallowEqual);

  useEffect(() => {
    // Prevent background page scrolling when modal is opened
    document.body.classList.toggle('scroll-block', openedModals.length > 0);
  }, [openedModals]);

  return null;
}

export function ClientWrapperComponent({ router, children }) {
  let RouterWrapper = ({ children }) => (<>{children}</>);
  const dispatch = useDispatch();

  if (router) {
    switch (router.type) {
      case 'BrowserRouter':
        RouterWrapper = ({ children }) => (<BrowserRouter>{children}</BrowserRouter>)
        break;
    }
  }

  useEffect(() => {
    dispatch({ type: 'CLIENT_HYDRATED' });

    // Init Angular after React hydration
    const html = document.documentElement;
    if (html.dataset.ngAppName && !window.ngAppInit) {
      window.ngAppInit = true;
      angular.element(document).ready(function(){
        angular.bootstrap(html, [html.dataset.ngAppName]);

        const event = new Event('LayoutHydrated');
        document.dispatchEvent(event);
      });
    }
  }, []);

  return (
    <RouterWrapper>
      {children}
      <HandlerComponents/>
    </RouterWrapper>
  );
}
