const defaultState = {
  __CLIENT_HYDRATED__: false,
  modals: [],
  openedModals: [],
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case 'MODAL_OPENED':
      return {
        ...state,
        openedModals: _.uniq([payload, ...state.openedModals]),
      }
    case 'MODAL_CLOSED':
      return {
        ...state,
        openedModals: _.uniq(_.without(state.openedModals, payload)),
      }
    case 'MODAL_MOUNTED':
      return {
        ...state,
        modals: [...state.modals, payload]
      }
    case 'CLIENT_HYDRATED':
      return {
        ...state,
        __CLIENT_HYDRATED__: true,
      };
    default:
      return state;
  }
};
