import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware, { END } from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from '../sagas';
import _ from 'lodash';

const DEFAULT_SCOPES = [
  'layout',
  'env',
  'user'
];

export default function getStore(scope = null) {
  const sagaMiddleware = createSagaMiddleware();

  let state = {};

  if (typeof window !== 'undefined') {
    if (window.__SSR_STORE_STATE__) {
      state = { preloadedState: window.__SSR_STORE_STATE__ };
      delete window.__SSR_STORE_STATE__;
    } else if (window.defaultState) {
      state = { preloadedState: window.defaultState };
    }
  }

  const store = configureStore({
    reducer: scope
      ? _.pick(rootReducer, [...scope, ...DEFAULT_SCOPES])
      : rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([sagaMiddleware]),
    ...state,
  });

  if (typeof window !== 'undefined' && window.document) {
    sagaMiddleware.run(rootSaga);
  } else {
    store.runSaga = sagaMiddleware.run;
    store.close = () => store.dispatch(END); //eslint-disable-line
  }

  return store;
}
