'use strict';

// Private functions
function secrets() {
  const { env } = process;

  let apiBase = env.API_BASE_URL;
  let searchBase = env.SEARCH_BASE_URL;

  if (env.TSF_BACKEND_PROXY) {
    apiBase = `https://${env.TSF_BACKEND_PROXY}.ngrok.io`;
  }

  if (env.TSF_SEARCH_PROXY) {
    searchBase = `https://${env.TSF_SEARCH_PROXY}.ngrok.io`;
  }

  return {
    env: env.NODE_ENV,
    paymentApiBase: env.PAYMENT_API_BASE_URL,
    apiBase: apiBase,
    apiCookieName: env.API_COOKIE_NAME,
    searchBase: searchBase,
    cdnBaseUrl: env.CDN_BASE_URL,
    webpackUrl: `http://${env.WEBPACK_DEV_SERVER_HOST || 'localhost'}:${env.WEBPACK_DEV_SERVER_PORT || 8080}`,
    redisUrl: env.REDISCLOUD_URL || 'redis://127.0.0.1:6379',
    geoRedisUrl: env.REDIS_GEOIP_URL || 'redis://127.0.0.1:6379',
    gatsbyRedisUrl: env.GATSBY_REDIS_URL || 'redis://127.0.0.1:6379',

    facebook: {
      appId: env.FACEBOOK_API_KEY
    },

    filestack: {
      apiKey: env.FILESTACK_API_KEY,
      bucket: {
        listingPhotos: env.FILESTACK_BUCKET_LISTING_PHOTOS,
        fileAttachments: env.FILESTACK_BUCKET_FILE_ATTACHMENTS
      },
      cdnPath: env.FILESTACK_CDN_PATH
    },

    google: {
      apiKey: env.GOOGLE_API_KEY
    },

    mapbox: {
      apiKey: env.MAPBOX_TOKEN
    },

    logEntries: {
      apiKey: env.LOGENTRIES_TOKEN
    },

    cookies: {
      secret: env.COOKIE_SECRET
    },

    pusher: {
      apiKey: env.PUSHER_API_KEY
    },

    segment: {
      apiKey: env.SEGMENT_API_KEY
    },

    stripe: {
      apiKey: env.STRIPE_PRIVATE_KEY
    },

    prismic: {
      apiEndpoint: env.PRISMIC_ENDPOINT,
      apiKey: env.PRISMIC_PRIVATE_KEY,
      webhookSecret: env.PRISMIC_WEBHOOK_SECRET,
      routeFile: env.PRISMIC_ROUTE_FILE
    },

    salesforce: {
      baseUrl: env.SALESFORCE_BASE_URL
    },

    recaptcha: {
      publicKey: env.RECAPTCHA_PUBLIC_KEY,
      privateKey: env.RECAPTCHA_PRIVATE_KEY
    },

    recombee: {
      dbName: env.RECOMBEE_DB_NAME,
      publicToken: env.RECOMBEE_PUBLIC_TOKEN
    }
  };
}

class Environment {

  constructor() {
    this.env = process.env.NODE_ENV || 'development';
    this.secrets = secrets(this.env);
  }

  forceHttps() {
    return this.env !== 'development';
  }

}

module.exports = new Environment();
