import _ from 'lodash';
import Cookies from 'js-cookie';

const analyticsMappingFields = {
  address: 'search_query',
  zoom: 'search_filter_geo_zoom',
  latitude: 'search_filter_geo_center_latitude',
  longitude: 'search_filter_geo_center_longitude',
  daily_price_gteq: 'search_filter_daily_price_min',
  daily_price_lteq: 'search_filter_daily_price_max',
  weekly_price_gteq: 'search_filter_weekly_price_min',
  weekly_price_lteq: 'search_filter_weekly_price_max',
  monthly_price_gteq: 'search_filter_monthly_price_min',
  monthly_price_lteq: 'search_filter_monthly_price_max',
  size_lteq: 'search_filter_space_size',
  duration: 'search_filter_duration',
  start_date: 'search_filter_start_date',
  end_date: 'search_filter_end_date',
  map_displayed: 'search_view_map_displayed',
};

const prepareSearchContextPayload = (listing, searchMeta, filters, positionInPage) => {
  const context = preparePayload(
    filters,
    searchMeta.total,
    searchMeta.pages,
    searchMeta.page,
  ).value();

  let cardPosition;
  let browsingContext;

  if (_.isNumber(positionInPage)) {
    browsingContext = 'list';
    cardPosition = positionInPage + ((searchMeta.page - 1) * 40) + 1;
  } else if (positionInPage === 'floating') {
    browsingContext = 'map';
  }

  // const listingProperties = new ListingProperties(listing);
  return _.merge(context, {
    card_ranking_score: listing.search_diagnostics._score,
    card_position_in_search_results: cardPosition,
    // card_badge: _.get(listingProperties.getBadgeType(), 'name'),
    browsing_context: browsingContext,
    last_4_weeks_ctr: listing.search_diagnostics.last_4_weeks_ctr,
  });
};

function getListingFeatureName(id) {
  return _.find(window.listingFeatures, { value: id }).label;
}

function getSpaceTypeName(id) {
  return _.find(window.spaceTypes, { value: id }).label;
}

function getListingFeatureNames(filters) {
  const listingFeatureIds = filters && filters.listing_feature_ids_contains_array;

  return (listingFeatureIds || []).map(getListingFeatureName);
}

function getSpaceTypeNames(filters) {
  const spaceTypeIds = filters && filters.space_type_ids;

  return (spaceTypeIds || []).map(getSpaceTypeName);
}

function getPositionTypeNames(filters) {
  return (filters && filters.position_types) || [];
}

function getProjectTypeNames(filters) {
  const projectTypeIds = filters && filters.project_type_ids;
  return (projectTypeIds || []).map((id) => (_.find(window.projectTypes, { id }) || {}).name);
}

function getProjectTypeName(filters) {
  const projectTypeId = filters && filters.parent_project_type_ids_contains_element;

  return projectTypeId
    ? (_.find(window.searchFilterProjectTypes, { id: projectTypeId }) || {}).name
    : '';
}

function getSearchOrder(filters) {
  return (angular.isObject(filters) && filters.s) || null;
}

function getFootTraffic(filters) {
  return (filters && filters.foot_traffic) || null;
}

function mapFilters(filters) {
  const eventProperties = {};

  for (const name in analyticsMappingFields) {
    eventProperties[analyticsMappingFields[name]] = filters[name];
  }

  return eventProperties;
}

export const preparePayload = function (filters, count, pageCount, pageNumber) {
  return _({
    search_filter_space_features: getListingFeatureNames(filters),
    search_filter_space_types: getSpaceTypeNames(filters),
    search_filter_project_type: getProjectTypeName(filters),
    search_filter_access_type: getPositionTypeNames(filters),
    search_sort_by: getSearchOrder(filters),
    foot_traffic: getFootTraffic(filters),
  })
    .merge(mapFilters(filters))
    .merge({ results_total_count: count })
    .merge({ results_page_count: pageCount })
    .merge({ results_page_nb: pageNumber });
};
/*
export const track = function (filters, count, options, pageCount, pageNumber) {
  const payload = preparePayload(filters, count, pageCount, pageNumber);
  const mergedPayload = payload.merge(options).value();

  // return eventTracker.track('Listings Search Results Viewed', mergedPayload);
};
*/

export const prepareListingPayload = (listing) => ({
  listing_id: listing.id,
  listing_ref_number: listing.external_mappings.ref_number,
  listing_country: listing.country,
  listing_state: listing.state,
  listing_city: listing.city,
  listing_neighborhood: listing.neighborhood,
  // listing_price_time_grain: timeGrainService.timeGrain,
  // listing_price: listing['readable_native_' + timeGrainService.timeGrain + '_price'],
  listing_daily_price: listing.daily_price,
  listing_weekly_price: listing.weekly_price,
  listing_monthly_price: listing.monthly_price,
  // listing_currency_iso_code: currencyFromListing(listing),
  listing_rental_capcity: _.find(window.locationTypes, { id: listing.location_type_id }).label,
  listing_size: listing.size,
  listing_space_type: (_.find(window.spaceTypes, { id: listing.space_type_id }) || {}).name,
  listing_minimum_rental: listing.minimum_lease,
  listing_ideal_for: getProjectTypeNames({
    project_type_ids: listing.project_type_ids,
  }).join(', '),
  listing_amenities: getListingFeatureNames({
    listing_feature_ids: listing.listing_feature_ids,
  }).join(', '),
  listing_display_latitude: listing.display_latitude,
  listing_display_longitude: listing.display_longitude,
  listing_favorites_count: listing.favorite_count,
  listing_was_recently_booked: listing.was_recently_booked,
  listing_has_popular_project: !!listing.most_popular_project_type,
  listing_response_rate: listing.response_rate,
  listing_response_time_average: listing.response_time_average,
});

export const track = (name, payload) => {
  payload.search_view_map_displayed = !(Cookies.get('searchMapVisible') === 'false');

  analytics.track(name, payload);
};

export const trackDebounce = _.debounce((name, payload) => {
  analytics.track(name, payload);
}, 200);
/*
  return {
    // filters : search filters, count : listings number in the search results
    track: function(filters, count, options, pageCount, pageNumber) {
      const payload = preparePayload(filters, count, pageCount, pageNumber);
      const mergedPayload = payload.merge(options).value();

      return eventTracker.track('Listings Search Results Viewed', mergedPayload);
    },
    preparePayload: preparePayload,
    getProjectTypeNames: getProjectTypeNames,
    getListingFeatureNames: getListingFeatureNames,
    prepareSearchContextPayload: prepareSearchContextPayload
  };
*/
