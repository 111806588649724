import i18n from 'i18next';

let t;

// To initialize on both server/client sides containers
// On the server side, node's t() function must be accessible
// from the window object prior to initialization
export const initTranslation = (fn = null, prefix = '') => {
  t = typeof fn === 'function'
    ? (key, params) => fn(`${prefix}${key}`, params)
    : i18n.t.bind(i18n);
};

export { t };
