import { all, fork } from 'redux-saga/effects';

import searchFilterSagas from './searchFilter';
import userSagas from './user';

export default function* rootSaga() {
  yield all([
    fork(searchFilterSagas),
    fork(userSagas),
  ]);
}
