export const LISTING_SINGLE_SEARCH_PERFORMED = 'LISTING_SINGLE_SEARCH_PERFORMED';
export const LISTING_SINGLE_SEARCH_SUCCESS = 'LISTING_SINGLE_SEARCH_SUCCESS';
export const LISTING_SEARCH_PERFORMED = 'LISTING_SEARCH_PERFORMED';
export const LISTING_SEARCH_SUCCESS = 'LISTING_SEARCH_SUCCESS';
export const LISTING_SEARCH_ERROR = 'LISTING_SEARCH_ERROR';
export const UPDATE_SEARCH_FILTERS = 'UPDATE_SEARCH_FILTERS';
export const CLEAR_SEARCH_FILTERS = 'CLEAR_SEARCH_FILTERS';
export const SET_OPEN_SEARCH_FILTER = 'SET_OPEN_SEARCH_FILTER';
export const SET_MORE_FILTERS_OPEN = 'SET_MORE_FILTERS_OPEN';
export const SET_PAGE_SEARCH_RESULTS = 'SET_PAGE_SEARCH_RESULTS';

export const updateSearchFilters = (filterParameter) => ({
  type: UPDATE_SEARCH_FILTERS,
  payload: filterParameter,
});

export const clearSearchFilters = (filters = []) => ({
  type: CLEAR_SEARCH_FILTERS,
  payload: filters,
});

export const listingSearchError = () => ({ type: LISTING_SEARCH_ERROR });

export const listingSingleSearchPerformed = (id) => ({
  type: LISTING_SINGLE_SEARCH_PERFORMED,
  payload: id,
});

export const listingSingleSearchSuccess = (searchTerm) => ({
  type: LISTING_SINGLE_SEARCH_SUCCESS,
  payload: searchTerm,
});

export const listingSearchPerformed = (searchTerm) => ({
  type: LISTING_SEARCH_PERFORMED,
  payload: searchTerm,
});

export const listingSearchSuccess = (results) => ({
  type: LISTING_SEARCH_SUCCESS,
  payload: results,
});

export const setOpenSearchFilter = (filter) => ({
  type: SET_OPEN_SEARCH_FILTER,
  payload: filter,
});

export const setMoreFiltersOpen = (isOpen) => ({
  type: SET_MORE_FILTERS_OPEN,
  payload: isOpen,
});
