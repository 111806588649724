export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const USER_FETCH = 'USER_FETCH';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_FETCH_ERROR = 'USER_FETCH_ERROR';

export const CLIENT_SECRET_FETCH = 'CLIENT_SECRET_FETCH';
export const CLIENT_SECRET_FETCH_SUCCESS = 'CLIENT_SECRET_FETCH_SUCCESS';
export const CLIENT_SECRET_FETCH_ERROR = 'CLIENT_SECRET_FETCH_ERROR';

export const UPDATE_USER_LANGUAGE = 'UPDATE_USER_LANGUAGE';
export const UPDATE_USER_LANGUAGE_SUCCESS = 'UPDATE_USER_LANGUAGE_SUCCESS';

export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';

export const OPEN_BILLING_PAGE = 'OPEN_BILLING_PAGE';

export const updateUserLanguage = (language) => ({
  type: UPDATE_USER_LANGUAGE,
  payload: language,
});

export const updateUserLanguageSuccess = () => ({ type: UPDATE_USER_LANGUAGE_SUCCESS });

export const logoutUser = () => ({ type: LOGOUT_USER });
export const logoutUserSuccess = () => ({ type: LOGOUT_USER_SUCCESS });
export const fetchUser = (userId) => ({
  type: USER_FETCH,
  payload: userId,
});

export const userFetchError = (data) => ({
  type: USER_FETCH_ERROR,
  payload: data,
});

export const userFetchSuccess = (data) => ({
  type: USER_FETCH_SUCCESS,
  payload: data,
});

export const clientSecretFetch = (customerId) => ({
  type: CLIENT_SECRET_FETCH,
  payload: customerId,
});

export const clientSecretFetchSuccess = (data) => ({
  type: CLIENT_SECRET_FETCH_SUCCESS,
  payload: data,
});

export const clientSecretFetchError = (data) => ({
  type: CLIENT_SECRET_FETCH_ERROR,
  payload: data,
});

export const updateSubscription = (options) => ({
  type: UPDATE_SUBSCRIPTION,
  payload: options,
});

export const updateSubscriptionSuccess = (data) => ({
  type: UPDATE_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const openBillingPage = () => ({
  type: OPEN_BILLING_PAGE,
});
