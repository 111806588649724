import layoutReducer from './layout/reducer';
import envReducer from './env/reducer';
import userReducer from './user/reducer';
import searchFilterReducer from './searchFilter/reducer';
import listingReducer from './listing/reducer';
import mapReducer from './map/reducer';
import searchResultsReducer from './searchResults/reducer';

export default {
  // Default reducers
  layout: layoutReducer,
  env: envReducer,
  user: userReducer,

  // Search reducers
  searchFilters: searchFilterReducer,
  map: mapReducer,
  searchResults: searchResultsReducer,

  // Listing reducers
  listing: listingReducer,
};
