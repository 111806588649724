import Cookies from 'js-cookie';

const defaultViewport = {
  mapVisible: true, /* Map visibility is then updated server side
                        using the 'searchMapVisible' user cookie
                        in ssr container */
  MARKER_CLUSTER_ASSOCIATION: {},
  highlightedFeature: null,
  selectedMarker: null,
  latitude: 40.7299053,
  longitude: -73.9841412,
  zoom: 11,
  minZoom: 8,
  maxZoom: 18,
};

export default (state = defaultViewport, { type, payload }) => {
  switch (type) {
    case 'TOGGLE_MAP_VISIBILITY': {
      // Save user preference
      Cookies.set('searchMapVisible', payload);

      return {
        ...state,
        mapVisible: payload,
      };
    }

    case 'SET_MAP_VIEWPORT': {
      return {
        ...state,
        ...payload,
      };
    }

    case 'SELECT_MAP_MARKER': {
      return {
        ...state,
        selectedMarker: payload,
      };
    }

    case 'SET_MAP_HIGHLIGHTED_FEATURE': {
      return {
        ...state,
        highlightedFeature: state.MARKER_CLUSTER_ASSOCIATION[payload],
      };
    }

    case 'SET_MAP_MARKER_CLUSTER_ASSOCIATION': {
      return {
        ...state,
        MARKER_CLUSTER_ASSOCIATION: payload,
      };
    }

    default:
      return state;
  }
};
